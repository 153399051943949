import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { HeroInner } from "../components/hero"
import Image from "../components/Image"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import FeaturesPackBlock from "../components/features-pack-6-block"
import TestimonialsSlideshow from "../components/testimonials-slideshow2"
import { relativeToAbsulote } from "../util/common"

function PlatformPage({ data: { nodePlatform }, path, ...props }) {

  //console.log(path)
  const { title, section2Title, relationships, showCTA,testimonialsTitle, showTestimonials } = nodePlatform
  const { hero, introducing, section2Items, section4Items } = relationships
  const seo = getMetatags(nodePlatform.metatag, nodePlatform.relationships.OGImage?.localFile?.publicURL)
  const bgImage = nodePlatform.relationships.bgImage?.localFile?.publicURL
  //console.log(section4Items)
  //console.log(section4Items.length)
  return (
    <Layout showCta={showCTA} /* headerBg="bg-background-gray" dark */>
      <Seo {...seo} />
      <HeroInner {...hero} noImage={true} tag={title} />
      <Zebra items={introducing} bgImage={bgImage} />

      {section2Title && <FeaturesPackBlock title={section2Title} items={section2Items} showTitle={path === '/real-time-data'} />}

      {section4Items.length > 0 && <section className="lg:py-32 py-12">
        <div className="container">
          {section4Items.map((c, i) => (
            <div className="" key={i}>
              <Section4Card {...c} />
            </div>
          ))}
        </div>
      </section>}
      {showTestimonials && <TestimonialsSlideshow title={testimonialsTitle} isHomepage={false} />}
    </Layout>
  )
}


export default PlatformPage

//export const Head = () => <title>Overview Page</title>

const Zebra = ({ items, bgImage }) => {
  //console.log(bgImage)
  return (
    <section className="py-12 lg:py-32">
      {items.map((c, i) => {
        if (i % 2 === 0) {
          return (
            <div className="container flex lg:flex-row flex-col" key={i}>
              <div className="lg:w-1/2 bg-transparent relative">
                <CardTitleImage {...c} side="left" />
                <div className="absolute top-0 right-0 left-0 bottom-0   -z-10 bg-no-repeat bg-left	top-24 lg:top-0" style={{ backgroundImage: `url('${bgImage}')` }}></div>
              </div>
              <div className="lg:w-1/2 ">
                <div className="mr-auto  lg:px-8  h-full flex content-center items-center py-4 lg:py-0"><CardDescription {...c} /></div>
              </div>
            </div>
          )
        } else {
          return (
            <div className="container flex lg:flex-row flex-col-reverse" key={i}>
              <div className="lg:w-1/2  ">
                <div className="ml-auto lg:px-8 h-full flex content-center items-center py-4 lg:py-0"><CardDescription {...c} /></div>
              </div>
              <div className="lg:w-1/2 bg-transparent  relative		">
                <CardTitleImage {...c} side="right" />
                <div className="absolute top-0 right-0 left-0 bottom-0   -z-10  bg-no-repeat bg-right top-24 lg:top-0" style={{ backgroundImage: `url('${bgImage}')` }}></div>
              </div>
            </div>
          )
        }
      })}
    </section>
  )

}


const CardTitleImage = ({ title, description, description_html, relationships, image, side, ...props }) => {
  //console.log(image)
  return (
    <div className="py-12">

      <div className="font-bold lg:text-4xl text-28 lg:max-w-[500px] text-left ml-auto lg:mr-24" dangerouslySetInnerHTML={{ __html: title }} />
      <div className="my-8 ml-8 text-left">

        <Image image={relationships.image} alt={image.alt} className="block-inline max-w-[500px]" width={image?.width} height={image?.height} />
      </div>
    </div>
  )
}

const CardDescription = ({ title, description, description_html, relationships, image, ...props }) => {

  return (
    <div className="lg:font-normal font-light">
      <div className="mx-auto mb-4 mt-2 lg:max-w-[500px] lg:text-xl text-lg leading-6	lg:leading-8	 	" dangerouslySetInnerHTML={{ __html: relativeToAbsulote(description_html?.processed)}} />
    </div>
  )
}
/*
const CardImage = ({ relationships, image, ...props }) => {

  return (
    <Image image={relationships.image} alt={image.alt} className="" />
  )
}

const CardText = ({ label, title, description, description_html,  relationships, ...props }) => {

  return (
    <div className="">
      <div className="text-blue text-2xl font-black	uppercase" >{label}</div>
      <div className="font-bold text-4xl">{title}</div>
      <p className="my-4">{description}</p>
      <div className="mb-4 mt-2" dangerouslySetInnerHTML={{ __html: description_html.processed }} />

    </div>
  )
}
*/


const Section4Card = ({ ...props }) => {

  const { title, description, description_html, relationships, image } = props
  //console.log(props)
  return (
    <div className="flex flex-col lg:flex-row py-6 lg:space-x-12 justify-between" >
      <div className="lg:w-[540px] ">
        <div className="font-bold lg:text-4xl text-28 -lg:max-w-[500px] text-left " dangerouslySetInnerHTML={{ __html: title }} />
        <p className="my-4 text-2xl">{description}</p>

        <div className="lg:font-normal font-light">
          <div className="mr-auto mb-4 mt-2 lg:max-w-[500px] lg:text-xl text-lg leading-6	lg:leading-8	 	" dangerouslySetInnerHTML={{ __html: relativeToAbsulote(description_html?.processed) }} />
        </div>

      </div>
      <div className="lg:w-1/2  text-center mx-auto lg:block mt-8 lg:mt-0">
        <Image image={relationships?.image} alt={image?.alt} className="block-inline max-w-[500px] text-center mx-auto" width={image?.width} height={image?.height} />
      </div>
    </div>
  )
}


export const query = graphql`
  query platform($id: String!) {
    nodePlatform(id: { eq: $id }) {
      id
      title
      section2Title : field_title
      section2ATitle : field_title4
      showCTA: field_show_book_demo
      testimonialsTitle : field_title3
      showTestimonials: field_show_testimonials
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      relationships {
        OGImage: field_image{
          localFile {
            publicURL
          }
        }
        hero: field_hero {
          ...HeroContent
        }
        introducing: field_paragraph_repeter {
          ...ParagraphGenericBlock
        }
        section2Items: field_paragraph_repeater2 {
          ...ParagraphGenericBlock
        }
        section4Items: field_paragraph_repeater4{
          ...ParagraphGenericBlock
        }

        section2AItems: field_items {
          ...ParagraphImageAndText
        }
        bgImage: field_background_image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
